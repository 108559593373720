import {NextApiRequest, NextApiResponse} from "next";
import {getSession, withApiAuthRequired} from "@auth0/nextjs-auth0";
import {ExternalAPINextApiRequest} from "@/types/general-types";
import {ManagementClient} from "auth0";

export const ROLES_NS = "https://ch-cloud.epicorebiosystems.com/roles";
export const DB_ROLES_NS = "https://ch-cloud.epicorebiosystems.com/db_roles";

const withApiAuthRequiredDev = (fn: any) => async (req: ExternalAPINextApiRequest, res: NextApiResponse) => {

    // TODO REMOVE BEFORE RELEASE!
    if (req.headers["bypass"] === "epicore123") return await fn(req, res);

    const {
        sub: userId,
        [ROLES_NS]: roles = [],
        [DB_ROLES_NS]: dbRoles = []
    } = getSession(req, res)!.user;
    const mergedRoles = [...roles, ...dbRoles];
    if (!userId || !mergedRoles) {
        console.error("unauthorized");
        return res.status(401).send("Unauthorized");
    }
    return withApiAuthRequired(fn)(Object.assign(req, {userId, roles: mergedRoles}), res);

};

const withApiAuthRequiredSecret = (fn: any) => async (req: NextApiRequest, res: NextApiResponse) => {
    if (req.headers["authorization"] === process.env.API_SECRET) {
        return fn(req, res);
    } else {
        return res.status(401).send("Unauthorized");
    }
};

export {withApiAuthRequiredDev, withApiAuthRequiredSecret};
